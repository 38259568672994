







import { Component, Vue } from "vue-property-decorator";
import { buttonOptions } from "@/components/buttons/buttonOptions";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    Opciones: () => import("@/components/buttons/opciones.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class handler_ficha_inventario extends Vue {
  public get buttons() {
    var btn: buttonOptions[] = [];
    btn.push(
      new buttonOptions({
        id: 1,
        title: "Inventario",
        descripcion: "Formulario de inventario",
        routername: RouterNames.inventario_formulario,
        icono: "fad fa-inventory",
        EventMethod: this.inventario_formulario,
        permiso: "read_inventario",
      })
    );
    return btn;
  }

  public inventario_formulario() {
    this.$router.push({
      name: RouterNames.inventario_formulario,
      params: { id: this.$route.params.id },
    });
  }
}
